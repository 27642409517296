<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <transition>
          <b-button
            v-if="!listOrderChanged"
            class="py-50 px-3 mr-2"
            variant="outline-success"
            @click="selectAgent"
          >
              + {{ $t('common.terms.add') }}
          </b-button>
          <b-button
            v-else
            class="py-50 px-3"
            variant="outline-purple"
            @click="saveOrder"
          >
            <b-spinner
              v-if="orderLoading"
              small
              variant="purple"
            />
            <feather-icon
              v-else
              icon="RefreshCcwIcon"
            />
            {{ $t('common.terms.save_order') }}
          </b-button>
        </transition>
      </div>
     
    </div>
    <draggable
      v-model="agents"
      class="list-group list-group-flush cursor-pointer"
      tag="ul"
      ghostClass="bg-light-primary"
      @change="listChanged"
    >
      <transition-group type="transition" tag="li" name="flip-list" class="list-unstyled">
        <b-card
          v-for="(agent, index) in agents"
          :key="agent.id"
          class="mb-2 mt-1"
          bg-variant="dark-blue"
          body-class="px-1 py-1"
          tag="div"
        >
          <div class="">
            <div class="d-inline-block">
              <feather-icon
                class="d-inline mr-50 cursor-move"
                icon="MenuIcon"
              />
              <h4 class="d-inline mb-0">{{ $t(agent.enum_agent_action.label) }}</h4>
              <feather-icon
                class="mx-25 d-inline"
                icon="ArrowRightIcon"
                size="15"
              />
              <div class="d-inline mr-50 mt-25" :id="getID('register-1')" v-if="agent.register_1.source">
                <b-avatar
                  text="R1"
                  size="24"
                  
                  variant="light-primary"
                />
                {{ getSourceLabel(agent.register_1.source) }}: {{ agent.register_1.value }}
              </div>
              <div class="d-inline mr-50 mt-25" :id="getID('register-2')" v-if="agent.register_2.source"
                ><b-avatar
                  text="R2"
                  size="24"
                  variant="light-success"
                />
                {{ getSourceLabel(agent.register_2.source) }}: {{ agent.register_2.value }}
              </div>
              <div
                :id="getID('register-destiny')"
                v-if="agent.register_destiny.source"
                class="d-inline-block mr-50 mt-25"
              >
                <b-avatar
                  text="RD"
                  size="24"
                  variant="light-danger"
                />
                {{ getSourceLabel(agent.register_destiny.source) }}: {{ agent.register_destiny.value }}
              </div>
            </div>
            <div class="d-inline-block float-right">
              <b-button
                size="sm"
                variant="outline-warning"
                class="mr-50"
                @click="editAgent(agent, index)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                size="sm"
                variant="outline-danger"
                class="mr-50"
                :disabled="deleteLoading"
                @click="confirmDeleteAgent(agent, index)"
              > 
                <feather-icon
                  v-if="!deleteLoading"
                  icon="TrashIcon"
                />
                <b-spinner
                  v-else
                  small
                />
              </b-button>
            </div>
          </div>
        </b-card>
      </transition-group>
    </draggable>

    <agent-base
      v-if="agent_item && middlewareID"
      :key="'agent-sidebar-' + agentVersionKey"
      :component="agent_item.component" 
      width="70%"
      :agentID="isEdit ? editingAgent.id : undefined"
      :uuid="isEdit ? getID(editingAgent.id) : getID('agent-component')"
      :middlewareID="middlewareID"
      v-model="editingAgent"
      :agentBlockID="agentBlockSelected"
      @saved="onSaved"
      @agentClosed="agentClosed"
      :isDebugMode="checkIfDebugMode(agent_item)"
    />
    
    <modal-select-agent
      :key="`modal-select-agent`"
      :uuid="getID('select-agent-type')"
      @selected="setAgentItem"
      @addAgent="addAgent"
    />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BContainer, BButton, BAvatar, BSpinner } from 'bootstrap-vue';
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import Source from "@/custom/class/Agent/Source"
import TryCatchAgentBlock from '@/custom/class/Enum/TryCatch.js'
import Agents from "@/custom/class/Enum/Agents";
import AgentsComponents from "@/layouts/components/Transmission/Middleware/Agent/List";
import VSelect from 'vue-select'
import { makeToast } from '@/layouts/components/Popups'
import SourceValue from '@/custom/class/SourceValue';
import SourceInput from '@/custom/class/SourceInput';
import NormalizedAgent from '@/custom/class/NormalizedAgent';
import ModalSelectAgent from '@/layouts/components/Transmission/Middleware/Agent/ModalSelectAgent.vue'
import AgentBase from '@/layouts/components/Transmission/Middleware/Agent/List/AgentBase.vue'

  export default {
    components: {
      ...AgentsComponents,
      BCard,
      BRow,
      BCol,
      BContainer,
      BButton,
      BAvatar,
      draggable,
      VSelect,
      BSpinner,
      ModalSelectAgent,
      AgentBase
    },
    props: {
      middlewareID: {
        type: Number,
        required: true
      },
      value: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        uuidMap: {},
        timelineIsShown: null,
        agent_item: null,
        agentBlockSelected: 1,
        registers: null,
        editingAgent: new NormalizedAgent({}),
        editingIndex: null,
        lastAccessedAgent: null,
        isEdit: false,

        agentsAux: [],
        listOrderChanged: false,

        orderLoading: false,
        deleteLoading: false,
        agentVersionKey: 0,
      }
    },
    computed: {
      agents: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      agentsList() {
        const agents = new Agents();
        return agents.items;
      },
      tryCatchAgentBlock() {
        const tryCatch = new TryCatchAgentBlock();
        return tryCatch.items;
      },
      transmissionID() {
        return this.$route.params.transmissionID;
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.agent_item = this.agentsList[2];
        this.setAgentsAux()
      },
      editAgent(agent, index, toggleSidebar=true ) {

        this.isEdit = true;
        this.agent_item = this.agentsList[agent.enum_agent_action.id]
        this.editingAgent = new NormalizedAgent(agent);
        this.editingIndex = index;

        if (toggleSidebar){
          this.agentVersionKey = this.agentVersionKey + 1
          this.$nextTick(() => {
            this.$root.$emit('bv::toggle::collapse', this.getID(this.editingAgent.id));
          })          
        }

      },
      confirmDeleteAgent(agent, index) {
        this.$bvModal
          .msgBoxConfirm(
            this.$t("agent.modal.delete.message", {
              "agent-type": this.$t(agent.enum_agent_action.label),
            }),
            {
              title: this.$t("agent.modal.delete.title"),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
              autoFocusButton: 'ok',
            }
          )
          .then((value) => {
            if (value) {
              this.deleteLoading = true;
              this.$store.dispatch('deleteAgent', { id: agent.id, transmissionID: this.transmissionID } )
                .then(() => {
                  
                  this.agents.splice(index, 1)
                  this.setAgentsAux()
                  this.loadingDelete = false;
                  this.$emit('agentDeleted', index, agent)
                  makeToast({
                    title: this.$t("agent.toast.delete.success.title"),
                    text: this.$t("agent.toast.delete.success.message"),
                    variant: "success",
                    icon: "CheckIcon",
                  });
                  this.deleteLoading = false;
                })
                .catch(() => {
                  this.deleteLoading = false;

                  makeToast({
                    title: this.$t("agent.toast.delete.error.title"),
                    text: this.$t("agent.toast.delete.error.message"),
                    variant: "danger",
                    icon: "XIcon",
                  });
                });
            }
          })
          .catch((err)=>{
            console.error(err)
          })
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      getSourceLabel(id) {
        const source = new Source(id);
        return this.$t(source.label);
      },
      selectAgent() {
        this.$bvModal.show(this.getID("select-agent-type"));
      },
      addAgent() {
        if (this.agent_item == null) {
          makeToast({
            title: this.$t("middleware.toast.select_agent.error.title"),
            text: this.$t("middleware.toast.select_agent.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        } else {
          this.agentVersionKey = this.agentVersionKey + 1
          this.$nextTick(()=>{
            this.$root.$emit("bv::toggle::collapse", this.getID("agent-component"));
          })
        }
      },
      onSaved(payload, closeSidebar=true) {
        let obj = payload.response.data;
        if (this.agents == undefined) {
          this.agents = [];
        }

        if (this.isEdit) {
          
          this.$set(this.agents, this.editingIndex, this.setupEditingPayload(obj));
          this.setAgentsAux()

          if (closeSidebar){
            this.$root.$emit('bv::toggle::collapse', this.getID(this.editingAgent.id));
            this.clearEditingSetup()
          }
          this.$emit('agentEdited', this.editingIndex, obj)

        } else {
          this.agents.push(obj);
          this.setAgentsAux()
          this.$emit('agentAdded', this.agents.at(-1), obj)

          if (!closeSidebar){
            let idx = this.agents.findIndex(el => el.id == payload.response.data.id)
            this.$nextTick(()=>{
              this.editAgent(this.agents[idx], idx, false)
            })
          } else {
            this.$root.$emit("bv::toggle::collapse", this.getID("agent-component"));
          }

        }
      },
      agentClosed() {
        this.clearEditingSetup()
      },
      clearEditingSetup() {
        this.editingAgent = new NormalizedAgent({});
        this.isEdit = false;
        this.editingIndex = null;
      },
      setupEditingPayload(payload) {
        return {
          enum_agent_block: payload.enum_agent_block,
          enum_agent_action: payload.enum_agent_action,
          execution_order: payload.execution_order,
          fatal_on_fail: payload.fatal_on_fail,
          id: payload.id,
          register_1: {
            source: payload.enum_source_register_1,
            value: payload.register_1_value
          },
          register_2: {  
            source: payload.enum_source_register_2,
            value: payload.register_2_value
          },
          register_destiny: {  
            source: payload.enum_source_destiny_id,
            value: payload.destiny_value,
          },
          updated_at: payload.updated_at,
          created_at: payload.created_at,
        }
      },
      saveOrder() {
        this.orderLoading = true;

        let newList = [];

        this.agents.forEach((item, key) => {
          if (this.agentsAux[key].id != item.id) {
            newList.push({ id: item.id, execution_order: key + 1 });
          }
        });

        this.$store
          .dispatch("changeAgentsExecutionOrderList", { list: newList, transmissionID: this.$route.params.transmissionID })
          .then(() => {
            makeToast({
              title: this.$t("middleware.toast.agent_reorder.success.title"),
              text: this.$t("middleware.toast.agent_reorder.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.listOrderChanged = false
            this.orderLoading = false;
          })
          .catch(() => {
            makeToast({
              title: this.$t("middleware.toast.agent_reorder.error.title"),
              text: this.$t("middleware.toast.agent_reorder.error.message"),
              variant: "danger",
              icon: "XIcon",
            });
            this.orderLoading = false;
          });
      },
      setAgentsAux() {
        this.agentsAux = this.agents
      },
      listChanged() {
        this.listOrderChanged = true
      },
      setAgentItem(item) {
        this.agent_item = item
      },
      checkIfDebugMode(itm){
        const idx = structuredClone(this.editingIndex);
        const agents = structuredClone(this.agents);
        const a = itm && this.middlewareID && idx;
        return !!(a && agents && agents[idx] && agents[idx-1] && agents[idx-1].enum_agent_action.id == 29);
      }
    },
  }
</script>

<style lang="scss" scoped>

.v-enter {
  opacity: 0 !important;
}
.v-enter-active {
  transition: opacity 0.3s !important;
}

.v-leave-active {
  transition: opacity 0.3s !important;
  opacity: 0 !important;
}

</style>